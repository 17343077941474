import React, { useState } from "react";
import "./login.css";
import logo from "../../../Assets/logo.png";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const notify = (msg) =>
    toast(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const handleEmail = (e) => {
    setUsername(e.target.value);
  };
  const handlePass = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === "" || password === "") {
      return notify("Enter login credentials");
    }
    let success = false;
    if (username === "admin" && password === "admin123") {
      return navigate("/admin/dashboard");
    }
    if (!success) {
      notify("Invalid login credentials");
    }
  };

  return (
    <div className="login_pg">
      <ToastContainer />
      <div class="background">
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
      </div>
      <div class="content">
        <div class="login-lg">
          <h2 style={{ marginBottom: "100px" }}>Member Login</h2>
          {/* <div class='login-role'>
                        <div className='select-box' style={start?employee:admin}></div>
                        <p style={start?{color:'#000'}:{color:'#fff'}} onClick={() => setStart(false)}>I am admin</p>
                        <p style={start?{color:'#fff'}:{color:'#000'}} onClick={() => setStart(true)}>I am employee</p>
                    </div> */}
          <div>
            <div class="form__group">
              <input
                type="text"
                class="form__input"
                id="text"
                onChange={handleEmail}
                placeholder="Username"
                required
              ></input>
              <label for="text" class="form__label">
                Username
              </label>
            </div>
            <div class="form__group">
              <input
                type="password"
                class="form__input"
                id="password"
                onChange={handlePass}
                placeholder="Password"
                required
              ></input>
              <label for="password" class="form__label">
                Password
              </label>
            </div>
          </div>
          <button className="btn btn-login" onClick={handleSubmit}>
            Login
          </button>
        </div>
        <div class="cover-lg">
          {/* <img alt='' src={imgCover} className='cover-img'></img> */}
          {/* <h3>Artisan inventory system</h3> */}
          <img src={logo} alt=""></img>
        </div>
      </div>
    </div>
  );
};

export default Login;
