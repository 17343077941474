import React from "react";
import "./newProduct.css";
import { AiOutlineClose } from "react-icons/ai";

const NewProduct = ({ close, resetId }) => {
  return (
    <div className="new_products">
      <div className="add-product-card">
        <div className="add-card-top">
          <h2>Add New Product</h2>
          <AiOutlineClose
            id="btn-close"
            onClick={() => {
              close();
              resetId();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NewProduct;
