import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./rightPane.css";
import Products from "../Products/Products";
import PanelHeader from "../PanelHeader/PanelHeader";

const RightPane = ({ page }) => {
  return (
    <div className="right-pane">
      <PanelHeader />
      {page === 1 ? <Products /> : null}
    </div>
  );
};

export default RightPane;
