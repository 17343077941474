import React, { useState } from "react";
import "./leftPane.css";
import { Link } from "react-router-dom";
import { RiProductHuntFill } from "react-icons/ri";
import { FaBlogger } from "react-icons/fa";
import { BsNewspaper } from "react-icons/bs";
import { MdWorkspacesFilled } from "react-icons/md";
import { RiLogoutBoxRFill } from "react-icons/ri";

const LeftPane = ({ rt }) => {
  const [filter, setFilter] = useState(1);

  return (
    <div className="left-pane">
      <div className="company-logo-lp">
        <h3>Genesis Energy</h3>
      </div>
      <ul className="side-nav">
        {/* <Link to="/admin/dashbaord/products"> */}
        <li
          onClick={() => {
            setFilter(0);
            rt(1);
          }}
          style={{ backgroundColor: filter === 0 ? "#2e538a" : null }}
        >
          <RiProductHuntFill
            style={{ fontSize: "30px", marginRight: "10px" }}
          />
          <p>Product</p>
        </li>
        {/* </Link> */}
        <Link to="/dashboard/products">
          <li
            onClick={() => {
              setFilter(1);
            }}
            style={{ backgroundColor: filter === 1 ? "#2e538a" : null }}
          >
            <FaBlogger style={{ fontSize: "30px", marginRight: "10px" }} />
            <p>Blogs</p>
          </li>
        </Link>
        <Link to="/dashboard/products">
          <li
            onClick={() => {
              setFilter(2);
            }}
            style={{ backgroundColor: filter === 2 ? "#2e538a" : null }}
          >
            <BsNewspaper style={{ fontSize: "30px", marginRight: "10px" }} />
            <p>News</p>
          </li>
        </Link>
        <Link to="/dashboard/products">
          <li
            onClick={() => {
              setFilter(3);
            }}
            style={{ backgroundColor: filter === 3 ? "#2e538a" : null }}
          >
            <MdWorkspacesFilled
              style={{ fontSize: "30px", marginRight: "10px" }}
            />
            <p>Career</p>
          </li>
        </Link>
        <Link to="/dashboard/products">
          <li
            onClick={() => {
              setFilter(4);
            }}
            style={{ backgroundColor: filter === 4 ? "#2e538a" : null }}
          >
            <RiLogoutBoxRFill
              style={{ fontSize: "30px", marginRight: "10px" }}
            />
            <p>Logout</p>
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default LeftPane;
