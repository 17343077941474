import React from "react";
import "./panelHeader.css";

const PanelHeader = () => {
  return (
    <div className="panel-header">
      <h3>Products</h3>
    </div>
  );
};

export default PanelHeader;
