import React, { useState, useContext, useEffect } from "react";
import LeftPane from "../../../Components/AdminPanel/LeftPane/LeftPane";
import RightPane from "../../../Components/AdminPanel/RightPane/RightPane";
import "./dashboard.css";

const Dashboard = () => {
  const [page, setPage] = useState(0);

  return (
    <div className="dashboard">
      <LeftPane
        rt={(e) => {
          setPage(e);
        }}
      />
      <RightPane page={page} />
    </div>
  );
};

export default Dashboard;
