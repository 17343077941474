import React, { useEffect, useState } from "react";
import "./products.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import DeleteCard from "../Cards/DeleteCard/DeleteCard";
import Loader from "../../Loader/Loader";
import NewProduct from "../NewProduct/NewProduct";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState("");
  const [add, setAdd] = useState(false);
  const [id, setId] = useState(null);
  const [del, setDel] = useState(0);
  const [delId, setDelId] = useState(null);

  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, "products"));
    setProducts(
      querySnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
    );
    const appletonSnapshot = await getDocs(collection(db, "appleton"));
    setProducts((old) => [
      ...old,
      ...appletonSnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })),
    ]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterData = products.filter(({ data }) => {
    return data.name.toLowerCase().includes(filter.toLowerCase());
  });

  const onSearch = (e) => {
    setFilter(e.target.value);
  };

  const handleDelete = async (id) => {
    // for (var i = 0; i < 20; i++) {
    //   const desertRef = ref(storage, `tours/${id}/${i}`);
    //   if (desertRef) {
    //     deleteObject(desertRef)
    //       .then(() => {
    //         console.log("Deleted !");
    //       })
    //       .catch((error) => {
    //         console.log("Encountered error => ", error);
    //       });
    //   }
    // }
    // await deleteDoc(doc(db, "tours", id));
    // window.location.reload();
    console.log(id);
  };

  return (
    <div className="admin-product-pg">
      {add ? (
        <NewProduct
          id={id}
          close={() => {
            setAdd(false);
          }}
          resetId={() => {
            setId(null);
          }}
        />
      ) : null}
      {del ? (
        <DeleteCard
          del={() => {
            handleDelete(delId);
          }}
          close={() => {
            setDel(0);
          }}
        />
      ) : null}
      <h1 style={{ marginBottom: "20px" }}>Manage Products</h1>
      <input
        type="text"
        name=""
        id="search_box"
        onChange={onSearch}
        placeholder="start typing to search the product..."
      />
      <button
        className="btn-add_items"
        onClick={() => {
          setAdd(true);
        }}
      >
        New +
      </button>
      <table className="fl-table">
        <thead>
          <tr>
            <th></th>
            <th>Preview</th>
            <th>title</th>
            <th>Destination</th>
            <th>Discount</th>
            <th>Price</th>
            <th colSpan="2">Action</th>
          </tr>
        </thead>
        <tbody>
          {filterData.length > 0 ? (
            filterData.map((d, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>
                    <img
                      alt=""
                      src={d.data.img}
                      className="product-preview"
                    ></img>
                  </td>
                  <td>{d.data.name}</td>
                  <td>{d.data.brand}</td>
                  <td>{d.data.details}</td>
                  <td
                    className="btn-del"
                    onClick={() => {
                      setDelId(d.id);
                      setDel(1);
                    }}
                  >
                    Delete
                  </td>
                  <td
                    className="btn-edit"
                    onClick={() => {
                      // setId(d.id);
                      // setAdd(true);
                    }}
                  >
                    Edit
                  </td>
                </tr>
              );
            })
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loader />
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Products;
